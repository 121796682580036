import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser";
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
import { regexSanitizedString } from "@/lib/utils/regex"
validateInput = validateInputParent.validateInput
import { Flag } from "@/flags"
import { requestContext } from "@/stores/common/request-context";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { GroupStore } from "@/stores/group-store.core"
import { appNoticeManager } from "@/lib/managers/app-notice-manager"
import { pageCoverManager } from "@/lib/managers/page-cover-manager"
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { NotificationStore } from "@/stores/notification-store.core"
import { SavedViewStore } from "@/stores/saved-view-store.core"

### Models ###
import { Group } from "@/models/group"
import { PermissionLevel } from "@/models/permission-level"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { modalManager as modalManager2 } from "@/lib/managers/modal-manager-2/modal-manager-2"
import { Modal } from "@/lib/components/modals/modal";
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { SupportListPane } from "@/lib/components/popup/support-list-pane"
import { UserToolbarPane } from "@/lib/components/popup/user-toolbar-pane"
import { ToolbarNotificationsPane } from "@/lib/components/popup/toolbar-notifications-pane"

### UI Assets ###
import { ToolbarButton } from "@/lib/components/toolbar/toolbar-button"
import { registerIcons } from "@/icons/icon-components/register-components"

### Framework Includes ###
import ko from "knockout"

export class App extends PageContentViewModel
   constructor: ->
      super()

      registerIcons()

      @modalManager2 = modalManager2

      @enableWorkForcePlanningV1 = Flag.ENABLE_WORKFORCE_PLANNING_V1
      @enableEmbeddedAppUsers = requestContext.usingProcoreHostApp

      @showDeprecationBanner = ko.pureComputed =>
         defaultFlagValue = LaunchDarklyBrowser.getFlagValue("deprecation-banner")
         earlyFlagValue = LaunchDarklyBrowser.getFlagValue("early-deprecation-banner")
         userEmailDomain = authManager.authedUser().email()?.split("@").pop()
         if userEmailDomain == "procore.com" || userEmailDomain == "laborchart.com"
            return false
         return !@enableEmbeddedAppUsers && (defaultFlagValue || earlyFlagValue)

      @persistentNoticeMessage = ko.pureComputed =>
         return "To avoid unnecessary interruption, set up account access in Workforce Planning. Contact Support with questions."

      ###------------------------------------
         Permissions
      ------------------------------------###
      @isolatedPage = ko.observable(true)
      @contentViewModelName = ko.observable()
      @canViewPeople = ko.observable(false)
      @canViewProject = ko.observable(false)
      @canViewRequests = ko.observable(false)
      @canViewAssignments = ko.observable(false)
      @allowDataExporting = ko.observable(false)
      @canViewPeopeTags = ko.observable(false)
      @canAccessMapPage = ko.observable(false)
      @canAccessGanttPage = ko.observable(false)
      @canAccessTotalsPage = ko.observable(false)
      @canViewAlerts = ko.observable(false)
      @canViewPeopleTimeoff = ko.observable(false)
      @canViewPeopleActivity = ko.observable(false)
      @canViewProjectActivity = ko.observable(false)
      @canViewNewGantt = ko.observable(false)

      # Toolbar properties
      @titleOnly = ko.pureComputed =>
         subtitle = @subtitle()
         return !(subtitle and subtitle.length)
      @isToggleToolbar = ko.observable(false)
      @toolbarToggles = ko.observableArray()
      @leftControls = @getControlSetObservable(ToolbarButton.LOCATION.LEFT)
      @rightControls = @getControlSetObservable(ToolbarButton.LOCATION.RIGHT)

      @profilePictureUrl = ko.observable()
      @firstName = ko.observable()
      @lastName = ko.observable()
      @name = ko.pureComputed =>
         return "#{@firstName()} #{@lastName()}"
      @userInitials = ko.pureComputed =>
         initials = ''
         initials += @firstName().charAt(0) if @firstName()
         initials += @lastName().charAt(0) if @lastName()
         return initials
      @unreadNotificationCount = ko.observable(0)
      @formattedUnreadNotificationCount = ko.pureComputed =>
         if (@unreadNotificationCount() > 99)
            return "99+"
         else
            return @unreadNotificationCount()

      @hideGroupControl = ko.observable(false)

      @showingBackBtn = ko.observable(false)

      @selectedNavBucket = ko.observable()

      @showWorkforcePlanningName = @enableWorkForcePlanningV1
      @displayingNotice = appNoticeManager.selectedNotice
      @notificationManager = notificationManager

      # Nav
      @savedViews = ko.observableArray()
      # People Buckets
      @peopleNavSearch = ko.observable()

      # These are broken up for UI sake to protext against clearing
      # search bar on bad search.
      @peopleNavItems = ko.computed =>
         pages = [
            App.PageName.PEOPLE_LIST,
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)
      @filteredPeopleNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@peopleNavItems(), @peopleNavSearch())

      # Projects Buckets
      @projectsNavSearch = ko.observable()
      @projectNavItems = ko.computed =>
         pages = [
            App.PageName.PROJECT_LIST,
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)
      @filteredProjectNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@projectNavItems(), @projectsNavSearch())

      # Assignment Buckets
      @assignmentsNavSearch = ko.observable()
      @assignmentNavItems = ko.computed =>
         pages = [
            App.PageName.ASSIGNMENTS_PAGE,
            App.PageName.ASSIGNMENTS_LIST,
            App.PageName.GANTT
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)

      @filteredAssignmentNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@assignmentNavItems(), @assignmentsNavSearch())

      # Request Buckets
      @requestsNavSearch = ko.observable()
      @requestNavItems = ko.computed =>
         pages = [
            App.PageName.REQUESTS_LIST
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)
      @filteredRequestNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@requestNavItems(), @requestsNavSearch())

      # Time Off Buckets
      @timeOffNavSearch = ko.observable()
      @timeOffNavItems = ko.computed =>
         pages = [
            App.PageName.TIME_OFF_LIST
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)
      @filteredTimeOffNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@timeOffNavItems(), @timeOffNavSearch())

      # Dashboards Buckets
      @dashboardNavSearch = ko.observable()
      @dashboardNavItems = ko.computed =>
         pages = [
            App.PageName.ACTIVITY,
            App.PageName.TOTALS,
            App.PageName.MAP
         ]
         savedViews = ko.unwrap(@savedViews)
         groupId = authManager.selectedGroupId()

         return @computeNavBucketsSavedViews(pages, savedViews, groupId)
      @filteredDashboardNavItems = ko.computed =>
         return @searchNavBucketSavedViews(@dashboardNavItems(), @dashboardNavSearch())

      # Groups Drop Down
      @groups = ko.observableArray()
      @groupSearch = ko.observable(null)
      @selectedGroup = ko.observable()
      @displayGroupCover = ko.observable(false)
      @displayingGroups = ko.pureComputed =>
         displayGroups = @groups()

         if validateInput(@groupSearch())
            displayGroups = displayGroups.filter (group) =>
               group.name().toLowerCase().indexOf(@groupSearch().toLowerCase()) != -1

         return displayGroups

      # Toggable Controls
      @disableGroupDropDown = ko.pureComputed =>
         return pageCoverManager.isShowingPageCover()

      @toolbarSupportPopupBuilder = =>
         pageCoverManager.forcePageCoverFinished() if pageCoverManager.activePageCover?
         return new Popup("Support", Popup.FrameType.LEFT,null,
            [new SupportListPane()],
            [], ['toolbar-btn__popup--support-list'])

      @toolbarSupportPopupWrapper = {
         popupBuilder: @toolbarSupportPopupBuilder
         options: {}
      }


      @legacyToolbarSupportPopupBuilder = =>
         pageCoverManager.forcePageCoverFinished() if pageCoverManager.activePageCover?
         return new Popup("Support", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,            
            [new SupportListPane()],
            [], ['toolbar-btn__popup--support-list'])

      @legacyToolbarSupportPopupWrapper = {
         popupBuilder: @legacyToolbarSupportPopupBuilder
         options: {}
      }

      @toolbarUserPopupBuilder = =>
         pageCoverManager.forcePageCoverFinished() if pageCoverManager.activePageCover?
         return new Popup("User", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new UserToolbarPane()],
            [], ['toolbar-btn__popup--user-toolbar'])

      @toolbarUserPopupWrapper = {
         popupBuilder: @toolbarUserPopupBuilder
         options: {}
      }

      @toolbarNotificationsPopupBuilder = =>
         pageCoverManager.forcePageCoverFinished() if pageCoverManager.activePageCover?
         return new Popup("Unread Notifications", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new ToolbarNotificationsPane()],
            [], ['toolbar-btn__popup--toolbar-notifications'])

      @toolbarNotificationsPopupWrapper = {
         popupBuilder: @toolbarNotificationsPopupBuilder
         options: {}
      }

      @unreadConversationsIds = ko.observableArray([])

      # Modals
      @activeModalClass = modalManager.activeModalClass

      filterGroupsIfNotAdminAndIsUsingTypedGroups = (user, groups) ->
         return [] unless groups

         isFilterable = authManager.usingTypedGroups() and !user.permissionLevel().isAdmin()
         return if isFilterable then groups.filter((group) -> user.accessGroupIds().indexOf(group.id) != -1) else groups

      # Check if user is authed before trying to load any data.
      authManager.authedUser.subscribe (user) =>
         if user?
            @profilePictureUrl(user.profilePicUrl())
            @firstName(user.firstName())
            @lastName(user.lastName())
            @reloadNotificationCount()
            @canViewPeople(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE))
            @canViewProject(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT))
            @canViewRequests(authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS))
            @canViewAssignments(authManager.checkAuthAction(PermissionLevel.Action.VIEW_ASSIGNMENTS))
            @allowDataExporting(authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA))
            @canViewPeopeTags(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS))
            @canAccessMapPage(authManager.checkAuthAction(PermissionLevel.Action.ACCESS_MAP_PAGE))
            @canAccessGanttPage(authManager.checkAuthAction(PermissionLevel.Action.ACCESS_GANTT_PAGE))
            @canAccessTotalsPage(authManager.checkAuthAction(PermissionLevel.Action.ACCESS_TOTALS_PAGE))
            @canViewAlerts(authManager.checkAuthAction(PermissionLevel.Action.VIEW_ALERTS))
            @canViewPeopleTimeoff(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF))
            @canViewPeopleActivity(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_ACTIVITY))
            @canViewProjectActivity(authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ACTIVITY))
            @canViewNewGantt(PermissionLevel.Action.ACCESS_GANTT_PAGE && await LaunchDarklyBrowser.getFlagValue('new-gantt-enabled'))

            @loadAvailableSavedViews()

            fanoutManager.getSubscription("vm.App",
               FanoutManager.Channel.USERS_NOTIFICATIONS, () => @reloadNotificationCount())

            fanoutManager.getSubscription("vm.App",
               FanoutManager.Channel.SAVED_VIEWS, () => @loadAvailableSavedViews())

            groups = []
            stream = await GroupStore.findGroupsStream({}).stream
            for await group from stream
               groups.push(new Group(group))
            @groups(filterGroupsIfNotAdminAndIsUsingTypedGroups(user, groups))

            if !@selectedGroup()?
               localStorageGroupId = window.localStorage.getItem("selectedGroupId")
               authGroupId = authManager.authedUser().preferences().defaultGroupId() ? authManager.selectedGroupId()

               if localStorageGroupId
                  if localStorageGroupId == "my-groups"
                     @selectedGroup(new Group({name: "My Groups", id: "my-groups"}, true))
                  else
                     for group in @groups()
                        if group.id == localStorageGroupId
                           @selectedGroup(group)
                           break
               else if !authGroupId || authGroupId == "my-groups"
                  @selectedGroup(new Group({name: "My Groups", id: "my-groups"}, true))
               else
                  for group in @groups()
                     if group.id == authGroupId
                        @selectedGroup(group)
                        break

               if @selectedGroup()?
                  authManager.selectedGroupId(@selectedGroup().id)
                  router.updateUrlPathParam("groups", @selectedGroup().id)
                  window.localStorage.setItem("selectedGroupId", @selectedGroup().id)
               else
                  # Default everything to my-groups in the off chance that the selectedGroup does not get set properly.
                  @selectedGroup(new Group({name: "My Groups", id: "my-groups"}, true))
                  authManager.selectedGroupId("my-groups")
                  router.updateUrlPathParam("groups", "my-groups")
                  window.localStorage.setItem("selectedGroupId", "my-groups")

         else
            # TODO: Clear all in memory values when logged out
            @selectedGroup(null)

   computeNavBucketsSavedViews: (pages, savedViews, groupId) =>
      filteredViews = savedViews.filter((view) ->
         bucketPass = (pages.indexOf(view.page) != -1)

         groupPass = if groupId == "my-groups" then true else (
            (view.group_ids.length == 0) or
            view.group_ids.indexOf(groupId) != -1
         )

         return bucketPass and groupPass
      ).sort (a, b) ->
         aName = a.name.toLowerCase()
         bName = b.name.toLowerCase()
         if (aName < bName)
            return -1
         else if (aName > bName)
            return 1
         else
            return 0

      return filteredViews

   searchNavBucketSavedViews: (savedViews, search) ->
      unless search?
         return savedViews

      search = search.toLowerCase().trim()
      return savedViews.filter (item) ->
         return item.name.toLowerCase().trim().match(regexSanitizedString(search))

   loadAvailableSavedViews: =>
      # TODO: Try catch this.
      views = await SavedViewStore.getUsersSavedViews().payload
      @savedViews(views.data)

   enterMyGroups: ->
      group = new Group({name: "My Groups", id: "my-groups"}, true)
      fanoutManager.breakGroupsSubscriptions(authManager.selectedGroupId())
      authManager.selectedGroupId(group.id)
      router.updateUrlPathParam("groups", group.id)
      @selectedGroup(group)
      window.localStorage.setItem("selectedGroupId", group.id)
      @displayGroupCover(false)

   changeGroup: (group) =>
      fanoutManager.breakGroupsSubscriptions(authManager.selectedGroupId())
      authManager.selectedGroupId(group.id)
      router.updateUrlPathParam("groups", group.id)
      @selectedGroup(group)
      window.localStorage.setItem("selectedGroupId", group.id)
      @displayGroupCover(false)

   reloadNotificationCount: () =>
      try
         notificationData = await NotificationStore.getUnreadNotificationsCount().payload
         count = notificationData.data.unread_notification_count
         @unreadNotificationCount(count)
         authManager.authedUser().unreadNotificationCount(count)
      catch err
         return console.log "Error: ", err if err

   canEditSavedView: (savedView) ->
      return savedView.creator_id == authManager.authedUserId()

   editSavedView: (savedView, event) ->
      # Doing this as clickBubble: false isn't cutting it with the modal
      # instantiation.
      event.cancelBubble = true
      if event.stopPropagation
         event.stopPropagation()

      modal = new Modal()
      pane = new SaveViewPane(null, null, savedView)
      modal.setPanes([pane])
      modalManager.showModal modal, null, { class: "save-view-modal" }, (modal, modalStatus, observableData) =>
         if observableData.data?.deletedViewId?
            # Check if the view ID is in the URL
            currentParams = router.getCurrentQueryParams()
            if currentParams?['viewId']? and currentParams['viewId'] == observableData.data.deletedViewId
               router.removeQueryParam(null, "viewId", true)

   showBackBtn: ->
      @showingBackBtn(true)

   hideBackBtn: ->
      @showingBackBtn(false)

   navigateback: ->
      router.back()

   navigateToSettings: ->
      router.navigate(null, "/company/#{authManager.companyId()}/settings")

   navigateToSavedView: (viewConfig) =>
      @navigate(viewConfig.page, true, {viewId: viewConfig.id})

   navigateToPage: (page) ->
      @navigate(page, true)

   navigate: (page, force, params) =>
      force = false unless force
      if params?
         queryParams = "?"
         for key, val of params
            queryParams+="#{key}=#{val},"
         queryParams = queryParams.slice(0,-1)
      else
         queryParams = ""

      pageName = @getPageName(page)
      return unless pageName?

      # TODO: This is lame, clean up.
      if (pageName == "mainRoute-messages" or pageName == "mainRoute-assignmentAlerts" or
      pageName == "mainRoute-settings")
         router.navigate(pageName, "/company/#{authManager.companyId()}/#{page}#{queryParams}", force)
      else if (pageName == "mainRoute-home")
         router.navigate(pageName, "/#{page}#{queryParams}", force)
      else if (pageName == "mainRoute-lookAheadReport" or pageName == "mainRoute-assignmentHistoryReport" or
      pageName == "mainRoute-tagActionReport" or pageName == "mainRoute-manpowerReport")
         router.navigate(pageName, "/groups/#{authManager.selectedGroupId()}/reports/#{page}#{queryParams}", force)
      else
         router.navigate(pageName, "/groups/#{authManager.selectedGroupId()}/#{page}#{queryParams}", force)

   getPageName: (name) ->
      switch name
         when "assignments" then "mainRoute-assignmentsPage"
         when "assignments-list" then "mainRoute-assignmentsList"
         when "projects" then "mainRoute-projectList"
         when "projects-list" then "mainRoute-projectList"
         when "create-labor-plans" then "mainRoutes-laborPlans"
         when "people" then "mainRoute-peopleList"
         when "people-list" then "mainRoute-peopleList"
         when "requests" then "mainRoute-requestsList"
         when "requests-list" then "mainRoute-requestsList"
         when "time-off" then "mainRoute-timeOffList"
         when "time-off-list" then "mainRoute-timeOffList"
         when "map" then "mainRoute-map"
         when "messages" then "mainRoute-messages"
         when "reports" then "mainRoute-reports"
         when "assignment-alerts" then "mainRoute-assignmentAlerts"
         when "gantt" then "mainRoute-gantt"
         when "new-gantt" then "mainRoute-new-gantt"
         when "activity" then "mainRoute-activity"
         when "totals" then "mainRoute-totals"
         when "settings" then "mainRoute-settings"
         when "home" then "mainRoute-home"
         when "look-ahead" then "mainRoute-lookAheadReport"
         when "assignment-history" then "mainRoute-assignmentHistoryReport"
         when "tag-action" then "mainRoute-tagActionReport"
         when "workforce" then "mainRoute-manpowerReport"
         else null

   setContent: (viewName, viewModel, childKey, options) =>
      [viewName, viewModel, childKey, options] = assertArgs(
         arguments, optional(String), PageContentViewModel, optional(String), optional(Object))
      @isolatedPage(false)
      @notificationManager.dismissAllUnbound(viewName)
      setNewChild = =>
         @contentViewModelName(viewName or "")
         if childKey?
            @setChild(childKey, viewModel)
            child = @getChild(childKey)
            if typeof child.setUpToolbar == "function"
               child.setUpToolbar()
            child.title(@title())
         else
            @setChild("main", viewModel)

      # Clean up old VM if needed
      curChild = @getChild("main")
      if viewName? and viewName == @contentViewModelName()
         if options?.urlFragment?
            # ???: Potential memory bloat here with unused VM?
            return curChild.handleUrlFragment(options.urlFragment)

      if curChild?.dispose?
         curChild.dispose(setNewChild)
      else
         setNewChild()

   setIsolatedContent: (viewName, viewModel, options) ->
      assertArgs(arguments, optional(String), PageContentViewModel, optional(Object))
      # Needed for signing out.
      blockDispose = options?.blockDispose or false
      @isolatedPage(true)

      setNewChild = =>
         @setChild("isolated", viewModel)
      # Clean up old VM if needed
      curChild = @getChild("main")
      if curChild?.dispose? and !blockDispose
         curChild.dispose(setNewChild)
      else
         setNewChild()

   dismissNotice: ->
      appNoticeManager.dismissActiveNotice()

   toggleGroupCover: ->
      @groupSearch(null)
      @displayGroupCover(!@displayGroupCover())

   enableGroupSelection: =>
      @hideGroupControl(false)

   disableGroupSelection: =>
      @hideGroupControl(true)

   setNavBucket: (selection) =>
      @selectedNavBucket(selection)

   decrementUnreadConversationsIds: (id) ->
      unless @unreadConversationsIds().indexOf(id) == -1
         @unreadConversationsIds.remove(id)

# TODO: Move this out into a common spot. Starting to be used too many places.
App.NavBuckets = {
   HOME: "home",
   PEOPLE: "people",
   PROJECTS: "projects",
   ASSIGNMENTS: "assignments",
   REQUESTS: "requests",
   TIME_OFF: "time-off"
   DASHBOARDS: "dashboards",
   REPORTS: "reports",
   COMMUNICATIONS: "communications"
}

# TODO: Move this out into a common spot. Starting to be used too many places.
# TODO: Build a better system to couple this and route name.
App.PageName = {
   ACCEPT_INVITE: "accept-invite"
   ACTIVITY: "activity"
   ASSIGNMENT_ALERTS: "assignment-alerts"
   ASSIGNMENT_HISTORY_REPORT: "assignment-history-report"
   ASSIGNMENTS_LIST: "assignments-list"
   ASSIGNMENTS_PAGE: "assignments"
   GANTT_OLD: "gantt-old"
   GANTT: "gantt"
   HOME: "home"
   LOOK_AHEAD_REPORT: "look-ahead-report"
   MANPOWER_REPORT: "manpower-report"
   MAP: "map"
   MESSAGE_LIST: "message-list"
   MESSAGES: "messages"
   PEOPLE_LIST: "people"
   PERSON_DETAIL: "person-detail"
   PERSON_QR_PROFILE: "person-qr-profile"
   PROJECT_DETAIL: "project-detail"
   PROJECT_LIST: "projects"
   PROJECT_QR_PROFILE: "proejct-qr-profile"
   REPORTS: "reports"
   REQUESTS_LIST: "requests"
   REST_PASSWORD: "reset-password"
   SETTINGS: "settings"
   SIGN_IN: "sign-in"
   SIGN_UP: "sign-up"
   TAG_ACTION_REPORT: "tag-action-report"
   TIME_OFF_LIST: "time-off"
   TOTALS: "totals"
}

# TODO: Move this out into a common spot. Starting to be used too many places.
App.RouteName = {
   ACCEPT_INVITE: "mainRoute-acceptInvite"
   ACTIVITY: "mainRoute-activity"
   ASSIGNMENT_ALERTS: "mainRoute-assignmentAlerts"
   ASSIGNMENT_HISTORY_REPORT: "mainRoute-assignmentHistoryReport"
   ASSIGNMENTS_LIST: "mainRoute-assignmentsList"
   ASSIGNMENTS_PAGE: "mainRoute-assignmentsPage"
   GANTT_OLD: "mainRoute-gantt-old"
   GANTT: "mainRoute-gantt"
   HOME: "mainRoute-home"
   LOOK_AHEAD_REPORT: "mainRoute-lookAheadReport"
   MANPOWER_REPORT: "mainRoute-manpowerReport"
   MAP: "mainRoute-map"
   MESSAGE_LIST: "mainRoute-messageList"
   MESSAGES: "mainRoute-messages"
   PEOPLE_LIST: "mainRoute-peopleList"
   PERSON_DETAIL: "mainRoute-personDetail"
   PERSON_QR_PROFILE: "mainRoute-personQrProfile"
   PROJECT_DETAIL: "mainRoute-projectDetail"
   LABOR_PLANS: "mainRoute-laborPlans"
   PROJECT_LIST: "mainRoute-projectList"
   PROJECT_QR_PROFILE: "mainRoute-proejctQrProfile"
   REPORTS: "mainRoute-reports"
   REQUESTS_LIST: "mainRoute-requestsList"
   REST_PASSWORD: "mainRoute-resetPassword"
   SETTINGS: "mainRoute-settings"
   SIGN_IN: "mainRoute-signIn"
   SIGN_UP: "mainRoute-signUp"
   TAG_ACTION_REPORT: "mainRoute-tagActionReport"
   TIME_OFF_LIST: "mainRoute-timeOffList"
   TOTALS: "mainRoute-totals"
}

export app = new App()
